import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import Logo from "../images/logo-white.svg";
import Menu from "../images/menu-closed.svg";
import { useSpring, animated, config } from "react-spring";
function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  // const [bind, { height }] = useResizeAware();
  const props = useSpring({
    height: isExpanded ? 200 : 0,
    config: config.stiff,
  });
  return (
    <header className="bg-black">
      <animated.div className="flex flex-wrap items-center justify-between max-w-4xl p-4 mx-auto md:p-8">
        <Link className="flex items-center text-white no-underline" to="/">
          <img alt="Robert loforsen - dina mål, min vision" src={Logo} />
        </Link>

        <button
          className="flex items-center block px-3 py-2 text-white md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <img alt="Meny" src={Menu} />
        </button>
        <animated.div
          className={`md:block md:flex md:items-center w-full md:w-auto`}
          style={{ ...props }}
        >
          {[
            {
              route: `/#about`,
              title: `Om`,
            },
            {
              route: `/gallery`,
              title: `Galleri`,
            },

            {
              route: `/login`,
              title: `Klient-inloggning`,
            },
          ].map((link) => (
            <Link
              className="block mt-4 font-black text-white no-underline md:inline-block md:mt-0 md:ml-6"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </animated.div>
      </animated.div>
    </header>
  );
}

export default Header;
