import React from "react";

export default function Footer() {
  return (
    <footer className="bg-white">
      <nav className="flex justify-center max-w-4xl p-4 mx-auto text-sm md:p-8">
        <p className="text-gray-600 uppercase">
          ROBERTLOFORSEN.COM © All rights reserved.
        </p>
      </nav>
    </footer>
  );
}
